@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100&family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh !important;
}

#root {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hide-scrollbar::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
  background: transparent;
}

@media print {
  @page {
    margin: 0;
  }
  #section-to-print {
    padding: 0 !important;
  }
}


/* in this file I do all the overrides that can't be done inside theme.ts*/
.MuiDataGrid-iconSeparator {
  display: none !important;
}
